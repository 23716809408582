import { findIndex, includes } from "lodash";

export const items = [
  { title: "Publikationen", url: "/publikationen", key: "publikationen" },
  { title: "Projekte", url: "/projekte", key: "projekte" },
  { title: "Karriere", url: "/karriere", key: "karriere" },
  { title: "Über uns", url: "/uber-uns", key: "uber-uns" },
  { title: "Leistungen", url: "/leistungen", key: "leistungen" },
  { title: "Impressum | Kontakt", url: "/impressum", key: "impressum" },
];
export const ITEM_WIDTH = 48;

export const pos = { top: 64, left: 2 * ITEM_WIDTH, right: 3 * ITEM_WIDTH };

export const getPageKey = ({ pathname }) => {
  const pathKey = pathname.substring(1).replace("/", "");
  const pk = includes(pathKey, "projekte")
    ? "projekte"
    : includes(pathKey, "publikationen")
    ? "publikationen"
    : includes(pathKey, "karriere")
    ? "karriere"
    : pathKey;

  return pk;
};

export const getPageData = ({ pathname }) => {
  let pathKey = pathname.substring(1).replace("/", "");
  const pageKey =
    includes(pathKey, "projekte") && !includes(pathKey, "blog")
      ? "projekte"
      : includes(pathKey, "publikationen")
      ? "publikationen"
      : includes(pathKey, "karriere")
      ? "karriere"
      : pathKey;

  const isSingleProject =
    includes(pathKey, "projekte") &&
    !includes(pathKey, "publikationen") &&
    pathKey !== "projekte";

  const isSinglePublication =
    includes(pathKey, "publikationen") && pathKey !== "publikationen";

  const isSingleKarriere =
    includes(pathKey, "karriere") && pathKey !== "karriere";

  return { pageKey, isSingleProject, isSinglePublication, isSingleKarriere };
};

export const calculatePadding = ({ pathname }) => {
  const pk = getPageKey({ pathname });
  let padding;

  if (pk === "projekte") {
    // padding = [ITEM_WIDTH * 2, ITEM_WIDTH * 3 + 24];
    padding = [ITEM_WIDTH * 2, ITEM_WIDTH * 4];
  } else if (pk === "publikationen") {
    padding = [ITEM_WIDTH * 1 + 0, ITEM_WIDTH * 5 + 0];
  } else if (pk === "karriere") {
    padding = [ITEM_WIDTH * 3 + 0, ITEM_WIDTH * 3 + 0];
  } else if (pk === "") {
    padding = [0, ITEM_WIDTH * 6];
  } else {
    const index = findIndex(items, { key: pk });
    padding = [
      (index + 1) * ITEM_WIDTH,
      (items.length - (index + 1)) * ITEM_WIDTH,
    ];
  }

  return padding;
};
