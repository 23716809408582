import { css } from "@emotion/react";
import React from "react";
import { BLACK } from "@styles/colors";
import Contacts from "@components/Contacts";
import MembershipLinks from "@components/MembershipLinks";

const Footer = () => {
  return (
    <footer
      css={css`
        height: 64px;
        border-top: 2px solid ${BLACK};
        padding: 0px 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 20;
        background: white;
        background: #a01f2f;
        color: white;

        @media (max-width: 991px) {
          display: none;
        }
      `}
    >
      <Contacts />
      <MembershipLinks />
    </footer>
  );
};
export default Footer;
