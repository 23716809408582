import { css } from "@emotion/react";
import React from "react";
import { Text } from "./Typography";
import orelche from "@assets/adler.png";
import { Div } from "./Markup";

const Contacts = () => {
  return (
    <Div
      flex
      ai="center"
      css={css`
        @media (max-width: 767px) and (max-height: 600px) {
          display: none;
        }
        img {
          max-width: 40px;
          height: 40px;
          min-width: 40px;
          margin-right: 12px;
          margin-left: -8px;
        }

        @media (max-width: 991px) {
          flex-direction: column;

          img {
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 24px;
            max-width: 48px;
            height: 48px;
            min-width: 48px;
          }
        }
      `}
    >
      <img src={orelche} />
      <Text
        css={css`
          font-size: 12px !important;
          @media (max-width: 991px) {
            display: none !important;
          }
          color: white !important;
          a {
            color: white !important;
          }
        `}
        dangerouslySetInnerHTML={{
          __html: `
          HERMANN & VALENTINY und PARTNER Architekten ZT GmbH<br />
          Rainergasse 4/8 | 1040 Wien | www.hv-wien.at | <a href="mailto:office@hv-wien.at">office@hv-wien.at</a>`,
        }}
      />
      <Text
        css={css`
          font-size: 12px !important;
          margin: 8px 0px 0px;
          text-align: center;
          @media (min-width: 992px) {
            display: none !important;
          }
        `}
        dangerouslySetInnerHTML={{
          __html: `
          HERMANN & VALENTINY und PARTNER Architekten ZT GmbH<br />
          Rainergasse 4/8 | 1040 Wien<br />
          www.hv-wien.at | <a href="mailto:office@hv-wien.at">office@hv-wien.at</a>`,
        }}
      />
    </Div>
  );
};

export default Contacts;
